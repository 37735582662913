document.addEventListener('DOMContentLoaded', function () {
    let timeouts = [];
    const $main_banners = $('.main_banners');

    function setSlideTimeout(slideIndex) {
        const time = $($main_banners.slick('getSlick').$slides[slideIndex]).find('div.banner').data('time');
        if (time) {
            timeouts.push(setTimeout(function () {
                $main_banners.slick('slickNext');
            }, time));
        }
    }

    $main_banners.slick({
        dots: true,
        arrows: false,
        infinite: true,
        slidesToShow: 1,
        autoplay: true,
        autoplaySpeed: 30000,
    }).on('beforeChange', function (event, slick, currentSlide, nextSlide) {
        timeouts.forEach(clearTimeout);
        timeouts = [];
        setSlideTimeout(nextSlide);
    });

    setSlideTimeout(0);
});
